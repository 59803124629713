import React, { useState } from 'react'

import { graphql, Link } from 'gatsby'
// import Img from 'gatsby-image'

import Layout from "../components/layout"
import SEO from "../components/seo"

import { FaInfoCircle, FaTimesCircle } from 'react-icons/fa';

import loadable from '@loadable/component'
const FlipPageComponent = loadable(() => import('../components/flipPage'))

export const query = graphql`
    query($slug: String) {
        contentfulImaginariumParticipant (slug: {
            eq: $slug
        }) {
            name
            reflection {
                title
                imaginariumDay
                date(formatString: "MMMM Do, YYYY")
                description {
                    description
                }
                image {
                  fluid {
                    ...GatsbyContentfulFluid
                  }
                }
            }
        }
    }
    `

const Imaginarium = (props) => {

    const { 
        name,
        reflection
     } = props.data.contentfulImaginariumParticipant

    const reflections = reflection || []

    const [ currentPageIndex, setCurrentPageIndex ] = useState(0)
    const [ currentReflection, setCurrentReflection ] = useState(reflections[currentPageIndex])

    const [ showHelp, setShowHelp ] = useState(false)

    const totalReflectionCount = reflections.length

    const onPageChange = (pageIndex) => {
        setCurrentPageIndex(pageIndex)
        setCurrentReflection(reflections[pageIndex])
    }

    return (
        <Layout>
            <SEO title={`${name}'s Imaginarium Journal`} />

            <Link to="/imaginarium" state={{showRhizome: true, fromJournal: true}} className="blogpost-back-btn is-size-4">
                Back
            </Link>

            <section className="section">
                <div className="container">
                    {
                        name === 'Collective Reflections' ?
                            <h1 className="is-size-3 has-text-centered">
                                {name}
                            </h1>
                            :
                            <h1 className="is-size-3 has-text-centered">
                                {name}'s Imaginarium Journal
                            </h1>
                    }
                </div>
                {
                    showHelp ? 
                        <p className="is-size-5">
                            Flip page to turn {` `}
                            <FaTimesCircle onClick={() => setShowHelp(false)} className="journal-show-help" />
                        </p>
                        :
                        <FaInfoCircle onClick={() => setShowHelp(true)} className="journal-show-help is-size-5" />
                }
            </section>

            { currentReflection ?
                <>
                    <section className="section pt-1">
                        <div className="container">
                            <div className="columns content is-vcentered">
                                <div className="column is-one-third">
                                    <p className="is-size-3">{currentReflection.title}</p>
                                    <p className="is-size-5">{currentReflection.description && currentReflection.description.description}</p>
                                    <p className="is-size-5">{currentReflection.date}</p>
                                </div>
                                <FlipPageComponent onPageChange={onPageChange}>
                                    {
                                        reflections && reflections.map((reflection, index) => 
                                            
                                                <img key={index} src={reflection.image && reflection.image.fluid.src} 
                                                    alt={reflection.title} 
                                                    style={{width: '100%', height: '100%', cursor: 'pointer'}}
                                                    className="journal-img"
                                                />
                                            
                                        )
                                    }
                                </FlipPageComponent>
                            </div>
                        </div>
                    </section>

                    <section className="section has-text-centered pt-1 pb-1" style={{backgroundColor: '#b1799f', color: '#462652'}}>
                        <p className="is-size-5">Page {currentPageIndex+1} of {totalReflectionCount}</p>
                    </section>
                </>
                :
                <p className="has-text-centered">There's nothing here yet.</p>
            }
        </Layout>
    )
}

export default Imaginarium
